import React from "react";
import HistoryPart from "./HistoryPart";
const HistoryCard = ({idx,header,note,km,part,shop})=>{
    return(
    <>
        <p>&nbsp;</p>
        <div className="card border-danger bg-info mb-3">
            <div className="card-header nopol">
                {header} / km {km}<br />{shop}
            </div>
                <div className="card-body">
                    <table className="table table-secondary table-striped">
                    <thead></thead>
                    <tbody>
                    {
                    note.map((rec,i)=>{
                    return(<tr key={i}>
                    <td width='10'>{i+1}.</td>
                    <td>{rec}</td>
                    </tr>)
                    })
                    }
                    </tbody>
                    
                    </table>
                    <HistoryPart
                        part={part}                    
                    />
                </div>
            </div>
        </>
    );

};
export default HistoryCard;