import React from "react";

import logo2 from '../../images/SM_Landscape.png';
function Navbar(){
    return(
    <div className="container-fluid mx-auto">
        <div className="row">
            <div className="d-flex flex-column min-vh-4 justify-content-center align-items-center">
            <img src={logo2} alt="Logo" width={350}  className="d-inline-block mx-auto"/>
            </div>
            <nav className="navbar navbar-warning bg-warning">
                <div className="container-fluid">
                    <span className="navbar-brand mb-0 h1 mx-auto">Riwayat Servis Kendaraan</span>
                </div>
            </nav>
        </div>    
       
    </div>
    
        
    );
}
export default Navbar;