
import React, { Component } from 'react';
import Navbar from './components/inc/Navbar';
import Vehicle from './components/pages/Kendaraan';
import Slider from './components/inc/Slider';
import History from './components/pages/RiwayatScroll';
import './App.css';
class App extends Component{
  render() {
    return (
        <div className="d-flex flex-column min-vh-4 justify-content-center align-items-center">
          <Navbar/>
          <Slider/>
          <Vehicle/>
          <History/>
        </div>
    );
  }
  
}

export default App;
