import React from "react";

const Loader = () =>{
    return(
        <>
        <div className="container bg-light border-danger nopol">
            <div className="d-flex align-items-center">
            <strong>Memuat data, mohon menunggu ...</strong>
            <div className="spinner-border text-info ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        </>
       ); 
}
export default Loader;