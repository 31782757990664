import React, { useEffect, useState } from "react";
import axios from "axios";

import HistoryList from "../inc/HistoryList"; 
import Loader from "../inc/Loader"; 
import Loaded from "../inc/Loaded";

const PAGE_NUMBER = 1;
const API_URL = 'https://record.suryamotor.co.id/fecth/';

const queryParams = new URLSearchParams(window.location.search);
const id = queryParams.get('sess_id');
let hasMore = true;
const RiwayatScroll = () =>{
    const [coinsData, setCoinsData] = useState([]);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [loading, setLoading] = useState(false);

    

    useEffect(() => {
        setTimeout(async () => {
            const response = await axios.post(
                API_URL,
                {
                    sess_id: id,
                    code: "010000F1",
                    page: page
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                     }   
                 }
            );

            setCoinsData((prev) => {
                return [...prev, ...response.data];
            });
           
            setLoading(false);
            //console.log("loaded json");
             if(response.data.length === 0 ) {
                hasMore = false;
                
            }
        }, 1500);
    }, [page]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = async () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.scrollHeight
        ) {
            if(hasMore){
                if(loading===false){
                    setLoading(true);
                    setPage((prev) => prev + 1);
                    //console.log("wait loading");
                }
                
            }else {
                //console.log("no hasmore");
            }
            
        }
       
    };

    return (
        <>
            <HistoryList rs={coinsData} />
            {loading && <Loader />}
            {!hasMore && <Loaded />}
        </>
    );
};

export default RiwayatScroll;