import React from "react";

const Loaded = () =>{
    return(
        <div className="container bg-warning border-danger text-center nopol">
            <div className="align-items-center">
            <br />
            <strong><center>Semua data telah ditampilkan ...</center></strong>
            <br />
            </div>
        </div>
        
       ); 
}
export default Loaded;