import React, { Component } from "react";
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
const API_URL = 'https://record.suryamotor.co.id/fecth/';
class OnService extends Component{
    constructor(props){
        super(props);
        this.state = {result: {}, isLoading: true, bTampil: true}
        this.OnRefresh = this.OnRefresh.bind(this);
       
    }

    componentWillMount(){
      this.setState({isLoading: true});
    }

    componentDidMount(){
      /*
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get('sess_id');
      const user = {
        sess_id: id,
        code: "010000FA"
      };
        axios.post(API_URL,user,{headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }}).then(response => response.data).then((data)=>{
            this.setState({result: data});
            this.setState({isLoading: false});
          }) ;
          */
         this.OnRefresh();
    }
    OnRefresh(){
      this.setState({isLoading: true});
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get('sess_id');
      const user = {
        sess_id: id,
        code: "010000FA"
      };
      axios.post(API_URL,user,{headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }}).then(response => response.data).then((data)=>{
        this.setState({result: data});
        this.setState({isLoading: false});
      }) ;
    }
    render(){
      let bRes = false;
      let strLoad = (<div className="d-flex align-items-center">
              <strong>Memuat...</strong>
              <div className="spinner-grow text-info ms-auto" role="status" aria-hidden="true"></div>
            </div>);
      let strNo = strLoad;
      let strTipe =strLoad;
      let strStatus = strLoad;
      let strBiaya = strLoad;
      let strPros = strLoad;
      let intProg = 0; 
      let strBranch = strLoad;
      if(!this.state.isLoading){
          bRes = this.state.result.res;
          strNo = this.state.result.no;
          strTipe = this.state.result.tipe;
          strStatus = this.state.result.status;
          strBiaya = this.state.result.charge;
          strPros = this.state.result.progres;
          intProg = this.state.result.percentage;
          strBranch = this.state.result.branch;
          this.setState({bTampil: bRes});
       }
      if(!this.state.bTampil){
        return(<div>&nbsp;</div>);
      }else{
        return(
          <div className="container">
              <p>&nbsp;</p>
              <div className="card border-danger bg-warning text-bg-light mb-3">
              <div className="card-header nopol">
              Proses Servis<br />
              {strBranch}
              </div>
              <div className="card-body">
              <table className="table table-light table-striped">
                  <thead></thead>
                  <tbody>
                  <tr>
                      <td width="140">No Urut</td>
                      <td width="2">:</td>
                      <td>{strNo}</td>
                  </tr>
                  <tr>
                      <td width="140">Tipe</td>
                      <td width="2">:</td>
                      <td>{strTipe}</td>
                  </tr>
                  <tr>
                      <td width="140">Status</td>
                      <td width="2">:</td>
                      <td>{strStatus}</td>
                  </tr>
                  <tr>
                      <td width="140">Estimasi Biaya</td>
                      <td width="2">:</td>
                      <td>{strBiaya}</td>
                  </tr>
                  <tr>
                      <td width="140">Proses Pekerjaan</td>
                      <td width="2">:</td>
                      <td>{strPros}</td>
                  </tr>
                  <tr>
                      <td width="140">Progress</td>
                      <td width="2">:</td>
                      <td classNamelass="progressBar">
                      <ProgressBar now={intProg} label={`${intProg}% selesai`} animated />
                      </td>
                  </tr>
                  <tr>
                      <td colSpan="3">
                           <Button size="sm"
        variant="primary"
        disabled={this.state.isLoading}
        onClick={!this.state.isLoading ? this.OnRefresh : null}
      >
        {this.state.isLoading ? 'Memuat...' : 'Cek Progres'}
      </Button>
                      </td>
                  </tr>
                  </tbody>
              </table>
              </div>
              </div>
          </div>
        );
      }
      
    }
}
export default OnService;